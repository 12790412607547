import { retryOnceParams } from "@src/app/api/utils";
import {
  SHIFT_ACTION_CHECK_TYPES,
  SIGNED_TYPE,
  TIMESHEET_TYPE,
} from "@src/app/hcpShifts/constants";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { dataURLToBlob } from "@src/app/utils/mediaUpload";
import { environmentConfig } from "@src/appV2/environment";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants/userEvents";
import { logApiFailureEvent } from "@src/lib/analytics";
import {
  ExtraWorkedTimeParameters,
  Shift,
  ShiftStages,
  Shift_NEW,
  TimeRange,
  TimecardV2,
  UnverifiedShift,
} from "@src/lib/interface";
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import request from "superagent";

import { NoTimeSheetAvailableReq, RatingRequest } from "../../store/ongoingShifts/model";
import { getAuthHeader } from "../../superagent";
import { uploadTimesheetToS3Storage } from "../../utils/fileUpload";

export const DEFAULT_TIMESHEET_LOCATION = {
  LONGITUDE: "12",
  LATITUDE: "34",
};

const uploadSubmittedTimeSheet = async ({
  submitClockInOut,
  submitLunchInOut,
  shiftId,
  facilityEmployeeName,
  fileType,
  fileBlob,
  extraWorkedTime,
}: {
  submitClockInOut: TimeRange;
  submitLunchInOut: TimeRange;
  shiftId: string;
  facilityEmployeeName: string;
  fileType: string;
  fileBlob: Blob | undefined;
  extraWorkedTime?: ExtraWorkedTimeParameters[];
}): Promise<TimecardV2> => {
  if (!fileBlob) {
    throw new Error("fileBlob not found");
  }
  const fileUploadResult = await uploadTimesheetToS3Storage(fileBlob, fileType, shiftId);
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v2/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
      submitClockInOut,
      submitLunchInOut,
      digitallySignedBy: facilityEmployeeName,
      extraWorkedTime,
    });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });

  return timecard;
};

export const uploadCaliforniaTimesheet = async ({
  shiftId,
  submittedFiles,
  submitClockInOut,
  submitLunchInOut,
  digitallySignedByHCP,
  digitallySignedBy,
  stationWingUnitFloor,
  nursingServiceAssignment,
  location,
  extraWorkedTime,
}: {
  shiftId: string;
  submittedFiles: SelectedFile[];
  submitClockInOut: TimeRange;
  submitLunchInOut: TimeRange;
  digitallySignedByHCP: string;
  digitallySignedBy: string;
  stationWingUnitFloor: string;
  nursingServiceAssignment: string;
  location?: string[];
  extraWorkedTime?: ExtraWorkedTimeParameters[];
}): Promise<TimecardV2 | undefined> => {
  const [
    { file: hcpSignatureFile, type: hcpSignatureFileType },
    { file: hcfSignatureFile, type: hcfSignatureFileType },
  ] = submittedFiles;
  const [hcpSignatureFileBlob, hcfSignatureFileBlob] = await Promise.all([
    dataURLToBlob(hcpSignatureFile!),
    dataURLToBlob(hcfSignatureFile!),
  ]);
  const [hcpSignatureResult, hcfSignatureResult] = await Promise.all([
    uploadTimesheetToS3Storage(hcpSignatureFileBlob, hcpSignatureFileType, shiftId),
    uploadTimesheetToS3Storage(hcfSignatureFileBlob, hcfSignatureFileType, shiftId),
  ]);
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const response = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v2/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: [
        {
          ...hcpSignatureResult[0],
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCP,
          digitallySignedBy: digitallySignedByHCP,
        },
        {
          ...hcfSignatureResult[0],
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCF,
          digitallySignedBy: digitallySignedBy,
        },
      ],
      location,
      submitClockInOut,
      submitLunchInOut,
      digitallySignedByHCP,
      digitallySignedBy,
      stationWingUnitFloor,
      nursingServiceAssignment,
      extraWorkedTime,
    });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  return response?.body?.response?.timecard;
};

const updateShiftTimecardUrl = async (fileUploadResult, shiftId) => {
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId: shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
    });

  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  return timecard;
};

const uploadTimecardV2 = async (
  type: string,
  fileBlob: Blob | undefined,
  shiftId: string
): Promise<TimecardV2> => {
  if (!fileBlob) {
    throw new Error("fileBlob not found");
  }
  const fileUploadResult = await uploadTimesheetToS3Storage(fileBlob, type, shiftId);
  const timecard = await updateShiftTimecardUrl(fileUploadResult, shiftId);
  return timecard;
};

const addRating = async (req: RatingRequest): Promise<Shift> => {
  const { body } = await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/rating`)
    .set(await getAuthHeader())
    .send({ reviewFor: "FACILITY", ...req });

  return body;
};

// used on non-NFC clock in
const recordShiftTime = async (
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  locationType: string,
  appType: string,
  onModalClose?: Function
): Promise<Shift_NEW | undefined> => {
  try {
    return await recordTimeKeeping({
      shiftId,
      stage,
      location,
      locationType,
      appType,
      shiftActionCheck: SHIFT_ACTION_CHECK_TYPES.LOCATION.toString(),
      // Non-NFC shifts require server time for HCP clock-ins, we don't send phone's value
      shiftActionTime: undefined,
      onModalClose,
    });
  } catch (err) {
    enqueueSnackbar({
      type: "error",
      message: "Error Recording Shift Time",
    });
  }
};

const recordShiftTimeKeepingForNFC = async (
  shiftId: string,
  stage: ShiftStages,
  appType: string,
  shiftActionTime: Date | string,
  shiftActionCheck: string,
  onModalClose?: Function
): Promise<Shift_NEW | undefined> => {
  try {
    return await recordTimeKeeping({
      shiftId,
      stage,
      location: [],
      locationType: "",
      appType,
      shiftActionCheck,
      shiftActionTime,
      onModalClose,
    });
  } catch (err) {
    enqueueSnackbar({
      type: "error",
      message: "Error Recording Shift Time",
    });
  }
};

const fetchAgentUnverifiedShifts = async (): Promise<UnverifiedShift[]> => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/getUnverifiedShifts`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body.response);
};

const setTimeSheetAvailability = async (query: NoTimeSheetAvailableReq): Promise<Shift_NEW> => {
  return request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/setTimeSheetAvailability`)
    .set(await getAuthHeader())
    .send(query)
    .then(({ body }) => body.response);
};

export interface RecordTimeKeepingParams {
  shiftId: string;
  stage: ShiftStages;
  location: number[];
  locationType: string;
  appType: string;
  shiftActionCheck: string;
  shiftActionTime?: Date | string;
  onModalClose?: Function;
}

async function recordTimeKeeping({
  shiftId,
  stage,
  location,
  locationType,
  appType,
  shiftActionCheck,
  shiftActionTime,
  onModalClose,
}: RecordTimeKeepingParams) {
  return request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/shifts/record_timekeeping_action/${shiftId}`)
    .retry(...retryOnceParams)
    .set(await getAuthHeader())
    .send({
      shiftId,
      stage,
      location: shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : location,
      locationType: shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : locationType,
      appType,
      connectivityMode: "ONLINE",
      shiftActionTime,
      shiftActionCheck,
    })
    .then(({ body }) => {
      onModalClose?.({}, { data: body });
      return body;
    })
    .catch((error) => {
      logApiFailureEvent(error);
      enqueueSnackbar({
        type: "error",
        message: error?.response?.body?.message || "Could not complete the action",
      });
      onModalClose?.({}, { error: error });
    });
}

interface ValidateTimeKeepingProps {
  shiftId: string;
  editedClockIn: string;
  editedClockOut: string;
}

export async function validateTimeKeeping({
  shiftId,
  editedClockIn,
  editedClockOut,
}: ValidateTimeKeepingProps) {
  const params = new URLSearchParams({
    editedClockIn,
    editedClockOut,
  }).toString();
  try {
    await request
      .get(
        `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/timekeeping/validate/${shiftId}?${params}`
      )
      .retry(...retryOnceParams)
      .set(await getAuthHeader())
      .send();
  } catch (error) {
    return error?.response?.body?.message as string;
  }
}

export const getUpcomingShiftsGrouped = async (): Promise<
  | {
      [key: string]: Shift;
    }
  | undefined
> => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/upcomingShiftsGrouped`)
    .set(await getAuthHeader())
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .then(({ body }) => body?.response);
};

export {
  uploadTimecardV2,
  addRating,
  recordShiftTime,
  fetchAgentUnverifiedShifts,
  setTimeSheetAvailability,
  uploadSubmittedTimeSheet,
  recordShiftTimeKeepingForNFC,
  recordTimeKeeping,
};
